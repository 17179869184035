.loading {
  width: 128px;
  height: 128px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -64px;
  margin-left: -64px;
}

.react-calendar {
  margin: 0 auto;

  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__tile--active,
  .react-calendar__tile:hover,
  .react-calendar__tile:focus {
    background: none !important;
    color: unset !important;
    &.react-calendar__month-view__days__day--neighboringMonth {
      color: #bebebe !important;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: unset !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #bebebe !important;
  }

  .react-calendar__tile {
    position: relative;
    cursor: initial;

    .event {
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      border-radius: 8px;
      color: #fff;
      padding: 8px 0;

      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
      }
    }
  }
}

.key {
  color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
}

.late {
  background: #e64a19;
}

.early {
  background: #388e3c;
}

.ontime {
  background: #ffa000;
}

.stripe-ontime,
.stripe-early {
  overflow: hidden;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.stripe-ontime::after {
  background: #ffa000;
}

.stripe-early::after {
  background: #388e3c;
}

.latewig-logo {
  position: relative;
  top: -2px;
}

.hero {
  .hero-img {
    border-radius: 20px;
  }

  .late {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 8px;
    color: #fff;
    margin-right: 16px;
  }

  .hero-ptr {
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: -10px;
    }
  }

  .scaler {
    transform-origin: top left;
  }
}

.popover {
  max-width: none !important;
}

.info-card {
  width: 300px;
  border-radius: 0;

  .card-title {
    font-size: 0.875rem;
  }

  p {
    font-size: 0.875rem;
    .text-body-secondary {
      color: rgb(33, 37, 41) !important;
    }
  }
}

.timeline {
  position: relative;
  .tl-bar {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 4px;
    border-left: 2px solid rgb(33, 37, 41);
  }
}

@media (max-width: 500px) {
  .hero-img.arrow {
    display: none;
  }

  .key {
    font-size: 12px;
  }
}

@media (min-width: 576px) {
  .scale {
    transform: scale(2) translateY(25%);
  }
}

@media (min-width: 768px) {
  .info-card {
    width: 424px;
    overflow: hidden;
  }

  .hero {
    .scaler {
      transform: scale(1.2);
    }
  }
}

@media (min-width: 992px) {
  .hero {
    .scaler {
      transform: scale(1.6);
    }
  }
}

@media (min-width: 1200px) {
  .hero {
    .scaler {
      transform: scale(1.8);
    }
  }
}

@media (min-width: 1400px) {
  .hero {
    .scaler {
      transform: scale(2);
    }
  }
}

body {
  overflow-x: hidden;
}
